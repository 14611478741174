$(function () {
    var $locale = $('html').prop('lang');
    var options = {
        format: 'YYYY-MM-DD',
        locale: $locale,
        debug: true
    };

    $('.datetimepicker').datetimepicker(options);

    var birthdayOptions = JSON.parse(JSON.stringify(options));
    birthdayOptions.maxDate = new Date();
    birthdayOptions.viewMode = 'years';

    $('.datetimepicker-birthday').datetimepicker(birthdayOptions);

    var rendezVousOptions = JSON.parse(JSON.stringify(options));
    rendezVousOptions.minDate = new Date();
    rendezVousOptions.useCurrent = false;

    $('.datetimepicker-rendez-vous').datetimepicker(rendezVousOptions);

    var hourOptions = JSON.parse(JSON.stringify(options));
    hourOptions.format = "HH:mm";
    hourOptions.useCurrent = false;

    $('.datetimepicker-hour').datetimepicker(hourOptions);
});
