(function() {
    'use strict';

    var $maps = $('.map-container');

    $(document).ready(function() {
        if (typeof $maps !== 'undefined') {
            desactivateMapMouseActionsUntilClick($maps);
        }
    });

    function desactivateMapMouseActionsUntilClick($maps) {
        $maps.find('iframe').css('pointer-events', 'none');

        $maps.click(function () {
            $(this).find('iframe').css('pointer-events', 'auto');
        });
    }
})();
