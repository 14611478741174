(function() {
    'use strict';

    var $contactLineAfter = $('.contact-line:after');
    var $headerContainer = $('header .container');

    function resizeContactLineAfter() {
        var windowWidth = $(window).width();
        var containerWidth = headerContainer.width();
        var containerRightPadding = parseint(headerContainer.css('padding-right'));
        var rightContainerMargin = (windowWidth - containerWidth) / 2;
        var contactLineAfterWidth = rightContainerMargin + containerRightPadding;

        $contactLineAfter.css('width', contactLineAfterWidth + 'px');
        $contactLineAfter.css('right', '-' + rightContainerMargin + 'px');
    }
})();