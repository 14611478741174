$(document).ready(function() {
    bootstrapSelect2();
});

$(window).resize(function() {
    bootstrapSelect2();
});

function bootstrapSelect2() {
    $('.select2:visible').each( function() {
        $(this).select2();
    });
}
